<template>
  <v-img
    v-if="namespace"
    height="100vh"
    src="/static/peapilt_1500_1000.jpg"
    class="white--text"
    gradient="to top, rgba(13, 3, 29, .60), rgba(25, 25, 38, .25)"
  >
    <v-container fill-height>
      <v-row
        align="center"
        class="pt-5 px-3"
      >
        <v-sheet
          color="transparent"
          dark
        >
          

          <p class="title font-weight-light">
            Ruumi <strong>vähe</strong>, pole <strong>aega</strong>, ära näe <strong>vaeva</strong>,
          </p>
          <h1
            class="mb-4 text-uppercase font-weight-black"
            :class="$vuetify.breakpoint.smAndUp ? 'display-3' : 'display-1'"
          >
            Usalda asjatundjaid
          </h1>

          <div class="py-4" />

          <v-row
            class="ma-0"
            justify="center"
            justify-md="start"
          >
            <v-btn
              class="mr-md-3 my-2"
              color="white"
              href="#services-info"
              large
              min-width="175"
              outlined
              rounded
              @click.prevent="$vuetify.goTo('#welcome')"
            >
              Uuri lähemalt
            </v-btn>

            <v-btn
              class="text--primary my-2"
              color="white"
              href="https://diga.ee/#/login"
              large
              min-width="175"
              rounded
            >
              Sisene kliendikeskkonda
            </v-btn>
          </v-row>
        </v-sheet>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
  export default {
    computed: {
      namespace () {
        return this.$route.name
      }
    }
  }
</script>
